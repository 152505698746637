import * as actionTypes from './actionTypes';
import { fetchJobScreeningList } from "../../../services/jobScreening.service";

export const setJobData = (data) => {
  return {
    type: actionTypes.SET_JOBSCREENING_JOBS,
    data,
  }
}

export const getScreeningJobs = (params="") => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_JOBSCREENING_LOADING });
    return fetchJobScreeningList(params).then(res => {
      dispatch(setJobData(res?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}
