import moment from "moment";
import {clsx} from "clsx"
import {twMerge} from "tailwind-merge"
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {timezones} from './timezones'
import {fetchSignedUrl, uploadDocumentToS3} from "../services/aws.service";
import React from "react";

dayjs.extend(utc)
dayjs.extend(timezone)

export const _isValidEmail = email => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regEx.test(email);
}

export const formatDate = (date, formate) => {
  return moment(date).format(formate || 'DD-MMM-YYYY')
}

export const numberOfDays = (date) => {
  return `${Math.abs(moment(date).diff(moment(), 'days')) + 1} day(s)`
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const capitalizeError = (field) => {
  if (field) {
    const result = field.replace(/([A-Z])/g, ' $1');
    return `${result[0].toUpperCase() + result.substring(1).toLowerCase()} is required`;
  }
  return '';
}

export const displayAddess = (city, state, country) => {
  const addressArray = [city, state, country];
  return addressArray.filter((value) => !!value).join(", ")
}

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const dayNames = [
  'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun',
]
export const getAddress = (location) => {
  const addressArray = [location?.addressLine1, location?.addressLine2, location?.city, location?.state, location?.country];
  return addressArray.filter((value) => !!value).join(", ")
}

export const formatCurrency = (value, currency="USD") => {
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  });
  return USDollar.format(value ?? 0)
}

export const getTimeZoneWithUtcOffset = (timezone) => {
  if(!timezone?.trim()) return ""
  let timeZoneOffset = timezone ? dayjs().tz(timezone).utcOffset() : "";
  let hours = timezone ? (timeZoneOffset >= 0 ? "+" : "-") + (Math.floor(timeZoneOffset / 60)) : "";
  let minutes = timezone ? Math.floor(timeZoneOffset % 60) : "";
  return `${timezone} UTC ${hours}:${minutes}`
}

export const isValidName = (name) => {
  return /^[a-zA-Z]/.test(name); // Check first character is alphabet not number
}

export const isValidBusinessName = (name) => {
  return /^[a-zA-Z0-9 ]+$/.test(name);
}

export const isValidHourlyRate = (rate) => {
  return /^\d{0,3}$/.test(rate);
}

export const isValidMonthlyRate = (rate) => {
  return /^\d{0,5}$/.test(rate);
}

export const getYearList = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const yearList = [];
  for (var i = currentYear; i >= 1950; i--) {
    const year = {
      label: i,
      value: i
    }
    yearList.push(year);
  }
  return yearList;
}

export const sortSkills = (firstSkill, secondSkill) => {
  // First level sorting should be based on year selected on skills
  // Second level sorting should be based on Alphabets
  // Primary skill should be always in first and above 2 rules must apply within Primary skills.

  if (!!firstSkill?.isPrimary && !secondSkill?.isPrimary) {
    return -1;
  } else if (!firstSkill?.isPrimary && !!secondSkill?.isPrimary) {
    return 1;
  }

  if (firstSkill?.experience !== secondSkill?.experience) {
    return secondSkill?.experience - firstSkill?.experience;
  }

  return firstSkill.name.localeCompare(secondSkill.name);
}


export const uploadToS3 = async (file, type, id) => {
  try {
    const payload = {
      s3Input: {
        contentType: file.type,
        fileName: `${type}.${file.name?.split('.').pop()}`,
        uploadType: type,
        uuid: id,
      },
    }
    const response = await fetchSignedUrl(payload)
    const {sUrl, pUrl} = response.data.signedUrl
    if (sUrl) {
      await uploadDocumentToS3(sUrl, file, file.type)
      return pUrl
    }
  } catch (error) {
    console.error(error)
  }
  return ""
}

export const timeZoneList = timezones;

export const getPhoneNumber = (phoneCode, phone) => {
  return `${phoneCode ? `+${phoneCode}` : ''} ${phone ? ` ${phone?.slice(phoneCode?.length, phone?.length)}` : 'Not available'}`
}

export const handleSkillSearch = (props, option, state) => {
  {
    if (!state?.inputValue && !!option?.alias) {
      return null
    }
    if (state?.inputValue && !!option?.alias) {
      return <li {...props}>{option.name} {`(alias)`}</li>
    }
    return <li {...props}>{option.name}</li>
  }
}

export const convertMarkdownToHTML = (markdownText) => {
  if(!markdownText?.trim()) return "";
  // Replace Markdown headers (e.g., # Header) with HTML headers (e.g., <h1>Header</h1>)
  markdownText = markdownText.replace(/^#\s(.+)$/gm, '<h1>$1</h1>');
  markdownText = markdownText.replace(/^##\s(.+)$/gm, '<h2>$1</h2>');
  markdownText = markdownText.replace(/^###\s(.+)$/gm, '<h3>$1</h3>');
  markdownText = markdownText.replace(/^####\s(.+)$/gm, '<h4>$1</h4>');
  markdownText = markdownText.replace(/^#####\s(.+)$/gm, '<h5>$1</h5>');
  markdownText = markdownText.replace(/^######\s(.+)$/gm, '<h6>$1</h6>');

  // Replace Markdown emphasis and strong emphasis with HTML tags
  markdownText = markdownText.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  markdownText = markdownText.replace(/\*(.*?)\*/g, '<em>$1</em>');

  // Replace Markdown unordered list items with HTML list items
  markdownText = markdownText.replace(/^\*\s(.+)$/gm, '<li>$1</li>');
  markdownText = markdownText.replace(/<\/li><li>/g, '</li><li>');
  markdownText = `<ul>${markdownText}</ul>`;

  // Replace Markdown links with HTML links
  markdownText = markdownText.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>');

  // Replace Markdown paragraphs with HTML paragraphs
  const paragraphs = markdownText.split(/\n\n/);
  markdownText = paragraphs.map((paragraph) => `<p>${paragraph}</p>`).join('');

  return markdownText;
}

let linkedinRegexWithGlobal = /(?:https:\/\/)?(?:[\w]+\.)?linkedin\.com\/in\/(?<profileName>[\w\-\_À-ÿ%]+)\/?/gm;
let skypeRegexWithGlobal = /(?<profileName>[a-z][a-z0-9\.,\-_]{5,31})(?:\?(?:add|call|chat|sendfile|userinfo))?/gm;
let mediumRegexWithGlobal = /(?:https:\/\/)?(?:www\.)?medium\.com\/@(?<profileName>[A-z0-9]+)(?:\?.*)?/gm;
let githubRegexWithGlobal = /(?:https:\/\/)?(?:www\.)?github\.com\/(?<profileName>[A-z0-9_-]+)\/?/gm;
let stackoverflowRegexWithGlobal = /(?:https:\/\/)?(?:www\.)?stackoverflow\.com\/users\/(?<id>[0-9]+)\/(?<profileName>[A-z0-9-_.]+)\/?/gm;

const profileRegex = {
  skype: skypeRegexWithGlobal,
  linkedin: linkedinRegexWithGlobal,
  medium: mediumRegexWithGlobal,
  github: githubRegexWithGlobal,
  stackoverflow: stackoverflowRegexWithGlobal
}

export const transformToResumeSchema = (inputJson) => {
  const validSocial = [
    'skype',
    'github',
    'linkedin',
    'website',
    'stackoverflow',
    'medium',
  ]

  return {
    "$schema": "https://raw.githubusercontent.com/jsonresume/resume-schema/v1.0.0/schema.json",
    'basics': {
      'name': `${inputJson.firstName} ${inputJson.lastName}`,
      'label': inputJson?.title,
      'email': inputJson?.contact?.email,
      'phone': inputJson?.contact?.phone,
      'summary': inputJson.about.summary,
      'image': inputJson?.photo,
      'location': {
        'address': inputJson?.address?.addressLine1 || inputJson?.address?.addressLine2,
        'city': inputJson?.address?.city,
        'countryCode': inputJson?.address?.country?.sortName
        // "region": inputJson?.address
      },
      'profiles': validSocial.filter((value) => {
        return !!inputJson?.contact?.[value]?.trim()
      }).map((value) => {
        let profileName;
        if (!!profileRegex[value] && !!inputJson?.contact?.[value]) {
          const matches = inputJson?.contact?.[value]?.matchAll(profileRegex[value])
          for (const match of matches) {

            const urlProfileName = match.groups?.profileName
            if (!!urlProfileName?.trim()) {
              profileName = urlProfileName
              break
            }
          }
        }
        return {
          'network': value,
          'username': profileName,
          'url': inputJson?.contact?.[value]
        }
      })
    },
    "work": (inputJson.workExperience || []).map(experience => ({
      "name": experience.companyName,
      "location": getAddress(experience.location),
      "description": experience.summary,
      "position": experience.title || 'N/A',
      "startDate": `${experience.fromYear}-01-01`,
      "endDate": experience.isCurrentlyWorking ? new Date() : `${experience.toYear}-01-01`,
      "summary": experience.responsibility,
      /*"highlights": [
        "Highlight 1",
        "Highlight 2" // Add highlights as needed
      ]*/
    })),
    "education": (inputJson.educationSection || []).map(education => ({
      "institution": education.universityName,
      "area": education.degreeName,
      // "studyType": "Bachelor", // You may need to modify this based on the actual data
      "startDate": `${education.fromYear}-01-01`,
      "endDate": `${education.toYear}-01-01`,
      // "score": "N/A",
      // "courses": []
    })),
    'skills': [{
      // 'name': 'Technical Skills',
      // 'level': 'Master',
      'keywords': (inputJson.skills || []).map(skill => skill.name)
    }],
    /*"languages": (inputJson.languages || []).map(language => ({
      "language": language.language || 'N/A',
      "fluency": language.fluency || 'N/A'
    })),*/
    /*"interests": (inputJson.interests || []).map(interest => ({
      "name": interest.name || 'N/A',
      "keywords": interest.keywords || []
    })),*/
    "projects": (inputJson.pastProjects || []).map(project => ({
      "name": project.name,
      "description": project.summary,
      // "highlights": project.links,
      "keywords": project.skills,
      // "startDate": "N/A",
      // "endDate": "N/A",
      "url": project?.links?.[0],
      // "roles": ["N/A"],
      // "entity": "N/A",
      // "type": "N/A"
    })),
    /*"volunteer": (inputJson.volunteer || []).map(volunteer => ({
      "organization": volunteer.organization || 'N/A',
      "position": volunteer.title || 'N/A',
      "url": volunteer.url || 'N/A',
      "startDate": volunteer.fromYear || 'N/A',
      "endDate": volunteer.toYear || 'N/A',
      "summary": volunteer.summary || 'N/A',
      "highlights": volunteer.highlights || []
    })),*/
    "awards": (inputJson.achievement || []).map(achievement => ({
      "title": achievement.title,
      "date": achievement.year ? `${achievement.year}-01-01` : undefined,
      // "awarder": "N/A",
      "summary": achievement.summary
    })),
    /*"publications": (inputJson.pastProjects || []).map(project => ({
      "name": project.name || 'N/A',
      "publisher": "N/A",
      "releaseDate": "N/A",
      "url": "N/A",
      "summary": project.summary || 'N/A'
    })),*/
    /*"references": (inputJson.references || []).map(reference => ({
      "name": reference.name || 'N/A',
      "reference": reference.reference || 'N/A'
    })),*/
    "meta": {
      "canonical": "https://raw.githubusercontent.com/jsonresume/resume-schema/master/resume.json",
      "version": "v1.0.0",
      "lastModified": "2017-12-24T15:53:00"
    }
  };
}

export function generateUUID(prefix = '') {
  let uuid = prefix;
  const randomValues = new Uint32Array(4);
  crypto.getRandomValues(randomValues);

  // Set version (4)
  randomValues[2] &= 0x0fff;
  randomValues[2] |= 0x4000;

  // Set variant (RFC4122)
  randomValues[3] &= 0x3fff;
  randomValues[3] |= 0x8000;

  for (let i = 0; i < randomValues.length; i++) {
    uuid += (i < 2 || i > 2 && i < 4 ? '-' : '') + randomValues[i].toString(16).padStart(4, '0');
  }

  return uuid;
}
