import * as actionTypes from './actionTypes';
import { fetchBusinessList } from "../../../services/business.service";

export const setBusinessData = (data) => {
  return {
    type: actionTypes.SET_BUSINESSES,
    data,
  }
}

export const getBusinesses = (params="") => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_BUSINESSES_LOADING })
    return fetchBusinessList(params).then(res => {
      dispatch(setBusinessData(res?.data?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}
