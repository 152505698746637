import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery, Avatar, ButtonBase } from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

import LogoSection from '../LogoSection';
import MenuList from './MenuList';
import ProfileSection from '../Header/ProfileSection';

import {IconMenu2, IconArrowBadgeRightFilled} from '@tabler/icons-react';

const drawerWidth = 260;

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {

    const { user } = useSelector((state) => {
        return {
          user: state?.auth?.user
        }
      });

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const drawer = (
        <>
            <Box sx={{ display: { xs: user?.userType === 'admin' ? 'flex' : 'block', md: user?.userType === 'admin' ? 'flex' : 'none' }, width: 240, }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
                {
                    user?.userType === 'admin' ?

                        <ButtonBase sx={{borderRadius: '12px', overflow: 'hidden'}}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background: theme.palette.secondary.light,
                                    color: theme.palette.secondary.dark,
                                    '&:hover': {
                                        background: theme.palette.secondary.dark,
                                        color: theme.palette.secondary.light
                                    }
                                }}
                                onClick={drawerToggle}
                                color="inherit"
                            >
                                <IconMenu2 stroke={1.5} size="1.3rem"/>
                            </Avatar>
                        </ButtonBase>
                    : null
                }

            </Box>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 125px)',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    <MenuList />
                </PerfectScrollbar>
              <ProfileSection />
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        overflow: 'visible',
                        [theme.breakpoints.up('md')]: {
                            top: user?.userType === 'admin' ?'0px' : '66px'
                        }
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
            {
                !drawerOpen && user?.userType === 'admin' ?

                    <ButtonBase sx={{borderRadius: '12px', overflow: 'hidden'}} className="open-icon-button" onClick={drawerToggle}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={drawerToggle}
                            color="inherit"
                        >
                            <IconArrowBadgeRightFilled stroke={1.5} size="10rem"/>
                        </Avatar>
                    </ButtonBase>
                : null
            }
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
