import React from "react";
import { useSelector } from 'react-redux';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Routes from './routes';

import themes from './themes';

import NavigationScroll from './elements/Navigation/NavigationScroll';

function App() {

  const customization = useSelector((state) => state.customization);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
        </NavigationScroll>
        <ToastContainer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
