import * as actionTypes from '../../actions/mails/actionTypes';

export const initialState = {
    mails: [],
    mailsDetails: {},
    isLoading: false,
    isListLoading:false,
};

const mailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MAILS: {
            return {
                ...state,
                mails: action.data
            };
        }
        case actionTypes.SET_SINGLE_MAIL: {
            const {_id, ...rest} = action.data
            return {
                ...state,
                mailsDetails: {...(state.mailsDetails || {}), [_id]: {_id, ...rest}}
            }
        }
        case actionTypes.SET_MAIL_LOAD_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actionTypes.SET_MAIL_LOAD_STOP: {
            return {
                ...state,
                isLoading: false
            }
        }
        case actionTypes.SET_MAIL_LIST_LOAD_START: {
            return {
                ...state,
                isListLoading: true
            }
        }
        case actionTypes.SET_MAIL_LIST_LOAD_STOP: {
            return {
                ...state,
                isListLoading: false
            }
        }
        default:
            return state;
    }
};

export default mailsReducer;
