import * as actionTypes from './actionTypes';
import {fetchProposals} from "../../../services/proposal.service";

export const setProposalData = (data) => {
    return {
        type: actionTypes.SET_PROPOSALS,
        data,
    }
}

export const getProposals = (params = {}) => {
    return dispatch => {
        return fetchProposals(params).then(res => {
            dispatch(setProposalData(res?.data));
            return res;
        }).catch(err => {
            console.log(err);
            return [];
        });
    }
}
