import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";

import * as mailsAction from '../../store/actions/mails'

import "./mails.scss"
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import {CreateButton} from "../../elements/CreateButton";
import MailDrawer from "../../molecules/Drawers/mailDrawer";
import StarsIcon from '@mui/icons-material/Stars';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

const getAIContent = (aiDecision, justification) => {
    let decisionContent;

    switch (aiDecision) {
        case "YES":
            decisionContent = (
                <div style={{
                    padding: '15px',
                    marginBottom: '20px',
                    border: '1px solid transparent',
                    borderRadius: '4px',
                    color: '#155724',
                    backgroundColor: '#d4edda',
                    borderColor: '#badbcc'
                }}>
                    <h3 style={{marginTop: '0', color: 'inherit'}}>Geekify Bot has scanned this mail and suggests that
                        this mail SHOULD BE SENT to the candidate as</h3>
                    <p style={{marginBottom: '0'}}><strong style={{fontWeight: 'bold'}}>Reason: </strong>{justification}
                    </p>
                </div>
            );
            break;
        case "NO":
            decisionContent = (
                <div style={{
                    padding: '15px',
                    marginBottom: '20px',
                    border: '1px solid transparent',
                    borderRadius: '4px',
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#badbcc'
                }}>
                    <h3 style={{marginTop: '0', color: 'inherit'}}>Geekify Bot has scanned this mail and suggests that
                        this mail SHOULD NOT be sent to the candidate as</h3>
                    <p style={{marginBottom: '0'}}><strong style={{fontWeight: 'bold'}}>Reason: </strong>{justification}
                    </p>
                </div>
            );
            break;
        case "UNSURE":
            decisionContent = (
                <div style={{
                    padding: '15px',
                    marginBottom: '20px',
                    border: '1px solid transparent',
                    borderRadius: '4px',
                    color: '#856404',
                    backgroundColor: '#fff3cd',
                    borderColor: '#badbcc'
                }}>
                    <h3 style={{marginTop: '0', color: 'inherit'}}>Geekify Bot has scanned this mail and not sure
                        whether its useful for the candidate or not.</h3>
                    <p style={{marginBottom: '0'}}><strong style={{fontWeight: 'bold'}}>Reason: </strong>{justification}
                    </p>
                </div>
            );
            break;
        default:
            decisionContent = "";
    }
    return decisionContent
}


const renderMail = (selectedMail, onReply, onForward) => {
    const from = decodeURIComponent(selectedMail.from).split("<")[0]
    const receivedDate = moment(selectedMail.createdAt).format('DD-MMM-YYYY, HH:MM')
    let html = selectedMail.htmlBody;

    return (
        <div style={{padding: 10}}>
            <div className={"mail-content-wrapper"}>
                <h1>{selectedMail.subject}</h1>
                <div style={{borderBottom: "1px solid black"}}>
                    <div className={"mail-header-wrapper"}>
                        {/*<Avatar alt={from} style={{width: 50, height: 50}}/>*/}
                        <div>
                            <div style={{
                                fontSize: "1rem",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px"
                            }}>From: <span
                                style={{
                                    // padding: "0.25rem 0.75rem",
                                    // backgroundColor: "#F4F6F9",
                                    // borderRadius: '3rem',
                                    // color: '#0073EC'
                                    fontWeight: 600
                                }}>{selectedMail.from}</span></div>
                            <div>{receivedDate}</div>
                        </div>
                        <div style={{marginLeft: "auto"}}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                                justifyContent: "flex-end"
                            }}>
                                <CreateButton onClick={() => onReply()}>Reply</CreateButton>
                                <CreateButton onClick={() => onForward()}>Forward</CreateButton>
                            </div>
                        </div>
                    </div>
                    <div>
                        {getAIContent(selectedMail.aiDecision, selectedMail.aiJustification)}
                    </div>
                </div>
                <div
                    className={"scroll-style-1 mail-body-wrapper"}
                    dangerouslySetInnerHTML={{__html: html}}
                />
            </div>
        </div>
    )
}

const MailPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [state, setState] = useState({})
    const [mailDrawerOpen, setMailDrawerState] = useState(false)
    const [action, setAction] = useState("")
    const [direction, changeDirection] = useState(searchParams.get("direction") || "incoming");
    const {mailsReducer: {mails, mailsDetails, isLoading, isListLoading}} = useSelector((state) => {
        return {
            mailsReducer: state.mailsReducer
        }
    })
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        refreshList()
    }, [direction])

    const refreshList = () => {
        dispatch(mailsAction.getMails(direction))
        navigate(`/mailbox?direction=${direction}`)
    }
    const getMail = (id) => {
        if (!mailsDetails[id]) {
            dispatch(mailsAction.getMailById(id));
        }
        setState({...state, selectedMail: id})
    }

    const toggleMailDrawer = (open) => (event, reason) => {
        if ((event.type === "keydown" && ((event).key === "Tab" || (event).key === "Shift")) || reason === "backdropClick") {
            return;
        }
        setMailDrawerState(open);
        if (!open) {
            setAction("")
        }
    };

    const onReply = () => {
        setAction("reply")
        toggleMailDrawer(true)({})
    }

    const onForward = () => {
        setAction("forward")
        toggleMailDrawer(true)({})
    }

    const selectedMail = mailsDetails[state.selectedMail];
    return (
        <div className={"mail-page-wrapper"}>
            <div style={{maxWidth: 400, boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 0px 2.6px"}}>
                <div className="mail-list-header icon-left">
                    <div>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                name="mailbox"
                                value={direction}
                                onChange={(event) => {
                                    changeDirection(event.target.value)
                                }
                                }
                            >
                                <FormControlLabel
                                    value="incoming"
                                    control={<Radio/>}
                                    label="Inbox"
                                />
                                <FormControlLabel
                                    value="outgoing"
                                    control={<Radio/>}
                                    label="Outbox"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>

                    <div>
                        <IconButton color="secondary" aria-label="Refresh" onClick={()=>refreshList()}>
                            <RefreshIcon/>
                        </IconButton>
                        <IconButton color="primary" aria-label="Compose" onClick={() => {
                            setAction("compose")
                            toggleMailDrawer(true)({})
                        }}>
                            <EditIcon/>
                        </IconButton>
                    </div>
                </div>

                <div className={"mail-list-wrapper scroll-style-1"}>
                {isListLoading ?
                    <div style={{ position: "relative", width: 380}}>
                            <CircularProgress
                                size={40}
                                color="inherit"
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }}
                            />
                    </div> :
                        mails?.map(({from, subject, createdAt, _id, aiDecision}, index) => {
                            const senderName = decodeURIComponent(from).split("<")
                            return (
                                <div
                                    className={`mail-card-wrapper ${state.selectedMail === _id ? "mail-card-wrapper-active" : ""}`}
                                    style={{
                                        borderBottom: index === (mails.length - 1) ? "0" : "1px solid #434F58",
                                    }} onClick={() => getMail(_id)}>
                                    <div className={"mail-card-from-row"}><span>{senderName?.[0]}</span>
                                        <span> {aiDecision === 'YES' ? <StarsIcon style={{
                                            color: "green",
                                            marginRight: "5px"
                                        }}/> : null} {moment(createdAt).format('DD-MM-YYYY HH:MM')}</span></div>
                                    <ul style={{listStyle: "unset", color: "#677983"}}>
                                        <li className={"mail-card-subject-row"}>{subject}</li>
                                    </ul>
                                </div>
                            )
                        })}
                </div>
            </div>
            <div style={{width: "100%", position: "relative"}}>
                {isLoading ?
                    <CircularProgress
                        size={40}
                        color="inherit"
                        style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                    /> : selectedMail ? renderMail(selectedMail, onReply, onForward) : <></>}
            </div>
            <MailDrawer
                open={mailDrawerOpen}
                toggleDrawer={toggleMailDrawer}
                type={action}
                from={action === "compose" ? "" : selectedMail?.to?.[0]}
                to={action === "compose" ? [] : [selectedMail?.sender]}
                subject={action === "reply" || action === "forward" ? `${selectedMail.subject}` : ""}
                body={(action === "compose" || action === "reply") ? "" : selectedMail?.htmlBody}
                direction={direction}
            />
        </div>
    )
}

export default MailPage
