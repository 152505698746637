import {requestWithToken} from "../apiConfigs/request";

export const fetchProposals = (params) => {
    return requestWithToken({
        url: '/proposal',
        method: 'GET',
        params
    });
}
export const fetchProposalById = (proposalId) => {
    return requestWithToken({
        url: `/proposal/${proposalId}`,
        method: 'GET',
    });
}

export const submitProposalRequest = (data) => {
    return requestWithToken({
        url: '/proposal',
        method: 'POST',
        data: data
    })
}

export const updateProposalRequest = (proposalId, data) => {
    return requestWithToken({
        url: `/proposal/${proposalId}`,
        method: 'PATCH',
        data: data
    })
}
