import React from 'react'
import {MDXEditor} from '@mdxeditor/editor/MDXEditor'
import {
  BlockTypeSelect,
  codeBlockPlugin,
  CodeToggle, corePluginHooks,
  frontmatterPlugin,
  headingsPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor'

// import {$getNearestNodeOfType, GenericHTMLNode} from '@mdxeditor/editor'
import {UndoRedo} from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo'
import {BoldItalicUnderlineToggles} from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles'

import '@mdxeditor/editor/style.css'
import './styles.scss';

export const ALL_PLUGINS = [
  toolbarPlugin({ toolbarContents: () => ( <> <UndoRedo /><BoldItalicUnderlineToggles /><CodeToggle /><BlockTypeSelect /><ListsToggle /></>) }),
  listsPlugin(),
  quotePlugin(),
  headingsPlugin({ allowedHeadingLevels: [1, 2, 3] }),
  tablePlugin(),
  thematicBreakPlugin(),
  frontmatterPlugin(),
  codeBlockPlugin({ defaultCodeBlockLanguage: 'txt' }),
  markdownShortcutPlugin()
]


const RichMDXEditor = (props) => {
  return (
    <MDXEditor
      className="slate-editor"
      markdown={props?.value}
      plugins={ALL_PLUGINS}
      contentEditableClassName="content-prose-class"
      onChange={(e, value) => {
        props?.setValue(e);
      }}
    />
  )
}

export default RichMDXEditor
