import {requestWithToken} from "../apiConfigs/request";

export const fetchJobTemplates = (query="") => {
  return requestWithToken({
    url: `/job-templates${query}`,
    method: 'GET'
  });
}

export const createJobTemplate = (data) => {
  return requestWithToken({
    url: '/job-templates',
    method: 'POST',
    data,
  });
}

export const updateJobTemplate = (templateId, data) => {
  return requestWithToken({
    url: `/job-templates/${templateId}`,
    method: 'PATCH',
    data,
  });
}

export const fetchJobTemplateById = (templateId) => {
  return requestWithToken({
    url: `/job-templates/${templateId}`,
    method: 'GET',
  });
}
