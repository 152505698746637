import {requestWithToken} from "../apiConfigs/request";

export const fetchTagsList = (query) => {
  return requestWithToken({
    url: `/tags${query}`,
    method: 'GET'
  });
}

export const createTag = (data) => {
  return requestWithToken({
    url: '/tags',
    method: 'POST',
    data,
  });
}

export const fetchTagById = (tagId) => {
  return requestWithToken({
      url: `/tags/${tagId}`,
      method: 'GET',
  });
}

export const updateTag = (tagId, data) => {
  return requestWithToken({
    url: `/tags/${tagId}`,
    method: 'PUT',
    data,
  });
}

export const deleteTag = (tagId) => {
  return requestWithToken({
    url: `/tags/${tagId}`,
    method: 'DELETE'
  });
}

