import axios from 'axios'
import {requestWithToken} from "../apiConfigs/request";

export const fetchSignedUrl = (data) => {
  return requestWithToken({
    url: '/aws/signedurl',
    method: 'POST',
    data
  })
}

export const fetchPublicSignedUrl = (data) => {
  return requestWithToken({
    url: '/public/signedurl',
    method: 'POST',
    data
  })
}

export const uploadDocumentToS3 = (url, data, contentType) => {
  return axios({
    method: 'put',
    url: url,
    headers: { 'Content-Type': contentType },
    data
  });
}
