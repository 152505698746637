import {mailSimpleRequest, requestWithToken} from "../apiConfigs/request";

export const fetchMails = (direction) => {
    return requestWithToken({
        url: `/mails?scope=short&direction=${direction}`,
        method: 'GET',
    })
}

export const fetchMailById = (mailId) => {
    return requestWithToken({
        url: `/mails/${mailId}`,
        method: 'GET',
    })
}

export const sendMail = (data) => {
    return mailSimpleRequest({
        url: `/send-mail`,
        method: 'POST',
        data
    })
}
