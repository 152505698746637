import { fetchSkills } from "../../../services/skill.service";
import * as actionTypes from './actionTypes';

export const setSkillsData = (data) => {
  return {
    type: actionTypes.SET_SKILLS,
    data,
  }
}

export const getSkills = () => {
  return dispatch => {
    return fetchSkills().then(res => {
      dispatch(setSkillsData(res));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}
