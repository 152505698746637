import {Chip} from "@mui/material";
import {styled} from "@mui/material/styles";


export const StyledChips = styled(Chip)`
    border-radius: 5px;
    max-height:25px;
    margin-right: 5px;
    margin: 0;
    .MuiChip-label {
        padding: 0 10px; 
        font-weight: 600;  
    }
`

