const timezones = [{
  "label": "UTC+04:30",
  "value": "UTC+04:30"
},
{
  "label": "UTC+05:45",
  "value": "UTC+05:45"
},
{
  "label": "UTC+05:30",
  "value": "UTC+05:30"
},
{
  "label": "UTC+08:45",
  "value": "UTC+08:45"
},
{
  "label": "UTC+10:30",
  "value": "UTC+10:30"
},
{
  "label": "UTC+09:30",
  "value": "UTC+09:30"
},
{
  "label": "UTC-02:30",
  "value": "UTC-02:30"
},
{
  "label": "UTC-01:00",
  "value": "UTC-01:00"
},
{
  "label": "UTC-12:00",
  "value": "UTC-12:00"
},
{
  "label": "UTC-02:00",
  "value": "UTC-02:00"
},
{
  "label": "UTC-03:00",
  "value": "UTC-03:00"
},
{
  "label": "UTC+06:00",
  "value": "UTC+06:00"
},
{
  "label": "UTC+07:00",
  "value": "UTC+07:00"
},
{
  "label": "UTC+06:30",
  "value": "UTC+06:30"
},
{
  "label": "UTC+05:00",
  "value": "UTC+05:00"
},
{
  "label": "UTC+04:00",
  "value": "UTC+04:00"
},
{
  "label": "UTC+03:30",
  "value": "UTC+03:30"
},
{
  "label": "UTC+12:45",
  "value": "UTC+12:45"
},
{
  "label": "UTC+14:00",
  "value": "UTC+14:00"
},
{
  "label": "UTC-09:30",
  "value": "UTC-09:30"
},
{
  "label": "UTC+11:00",
  "value": "UTC+11:00"
},
{
  "label": "UTC+13:00",
  "value": "UTC+13:00"
},
{
  "label": "UTC+12:00",
  "value": "UTC+12:00"
},
{
  "label": "UTC+10:00",
  "value": "UTC+10:00"
},
{
  "label": "UTC+02:00",
  "value": "UTC+02:00"
},
{
  "label": "UTC+09:00",
  "value": "UTC+09:00"
},
{
  "label": "UTC+08:00",
  "value": "UTC+08:00"
},
{
  "label": "UTC-08:00",
  "value": "UTC-08:00"
},
{
  "label": "UTC-09:00",
  "value": "UTC-09:00"
},
{
  "label": "UTC-10:00",
  "value": "UTC-10:00"
},
{
  "label": "UTC-05:00",
  "value": "UTC-05:00"
},
{
  "label": "UTC-04:00",
  "value": "UTC-04:00"
},
{
  "label": "UTC-06:00",
  "value": "UTC-06:00"
},
{
  "label": "UTC-07:00",
  "value": "UTC-07:00"
},
{
  "label": "UTC-11:00",
  "value": "UTC-11:00"
},
{
  "label": "UTC+03:00",
  "value": "UTC+03:00"
},
{
  "label": "UTC+01:00",
  "value": "UTC+01:00"
},
{
  "label": "UTC+00:00",
  "value": "UTC+00:00"
}
]

function compareTimezones(a, b) {
  const parseTimezone = (str) => {
    const sign = str[3] === '-' ? -1 : 1;
    const [hours, minutes] = str.slice(4).split(':').map(Number);
    return sign * (hours * 60 + minutes);
  };

  const timezoneA = parseTimezone(a.label);
  const timezoneB = parseTimezone(b.label);

  return timezoneA - timezoneB;
}

timezones.sort(compareTimezones);

export { timezones };