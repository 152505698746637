import * as actionTypes from '../../actions/job/actionTypes';
import * as jobApplicationTypes from '../../actions/jobApplication/actionTypes';

export const initialState = {
  jobs: [],
  jobApplications: [],
  isLoading: true
};

const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_JOBS:
      return {
        ...state,
        isLoading: false,
        jobs: action.data
      };
    case actionTypes.SET_JOBS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case jobApplicationTypes.SET_JOB_APPLICATIONS:
      return {
        ...state,
        jobApplications: action.payload.data,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default jobReducer;
