import moment from "moment";
import { store } from "../store";
import { logOutUser } from "../store/actions/auth";
import AuthService from "../services/auth.service";
import history from '../utils/customHistory';

export const _setToken = (data) => {
  localStorage.setItem('accessToken', data.access.token);
  localStorage.setItem('refreshToken', data.refresh.token);
  localStorage.setItem('expiryToken', data.access.expires);
}

let refrestTimer;
export const logout = async () => {
  if (refrestTimer) {
    clearInterval(refrestTimer)
    refrestTimer = null;
  }

  localStorage.clear();
  store.dispatch(logOutUser()).then(()=>{
    history.push('/login');
    window.location.reload();
  })
}

export const setupRefreshTimer = async () => {
  if (localStorage.getItem('accessToken')) {
    /*const expiryToken = localStorage.getItem('expiryToken')

    const currentTime = new Date();
    const expiryTime = new Date(parseInt(expiryToken, 10) * 1000); // Parse the timestamp
    const durationInSeconds = moment(expiryTime).diff(currentTime, 'seconds');

    refrestTimer = setInterval(async () => {
      await callRefresh()
    }, (durationInSeconds - 100) * 1000)*/
  } else {
    logout()
  }
}

export const callRefresh = async _ => {
  const refreshData = await AuthService.callRefresh().then((response) => {
    if (response.statusCode === 200 || response.statusCode === 201) {
      _setToken(response.data.tokens)
    } else {
      logout()
    }
  }).catch((err) => {
    logout()
  });
  return refreshData
}
