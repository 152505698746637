import * as mailService from "../../../services/mail.service"
import * as actionTypes from "./actionTypes"

export const getMails = (direction) => {
    return dispatch => {
        dispatch({type: actionTypes.SET_MAIL_LIST_LOAD_START});
        return mailService.fetchMails(direction).then(res => {
            dispatch({type: actionTypes.SET_MAILS, data: res?.data, direction});
            dispatch({type: actionTypes.SET_MAIL_LIST_LOAD_STOP});
            return res;
        }).catch(err => {
            console.log(err);
            dispatch({type: actionTypes.SET_MAIL_LIST_LOAD_STOP});
            return [];
        });
    }
}

export const getMailById = (mailId) => {
    return dispatch => {
        dispatch({type: actionTypes.SET_MAIL_LOAD_START});
        return mailService.fetchMailById(mailId).then(res => {
            dispatch({type: actionTypes.SET_SINGLE_MAIL, data: res?.data});
            dispatch({type: actionTypes.SET_MAIL_LOAD_STOP});
            return res;
        }).catch(err => {
            console.log(err);
            dispatch({type: actionTypes.SET_MAIL_LOAD_STOP});
            return [];
        });
    }
}

export const sendMail = (data) => {
    return (dispatch) => {
        return mailService.sendMail(data)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                console.log(err)
                throw err
            })
    }
}
