import {requestWithToken} from "../apiConfigs/request";

export const createJobScreening = (data) => {
  return requestWithToken({
    url: '/job-screening',
    method: 'POST',
    data,
  });
}

export const fetchJobScreeningById = (jobId) => {
  return requestWithToken({
      url: `/job-screening/${jobId}`,
      method: 'GET',
  });
}

export const fetchJobScreeningList = (query) => {
  return requestWithToken({
    url: `/job-screening${query}`,
    method: 'GET'
  });
}

export const updateJobScreening = (jobId, data) => {
  return requestWithToken({
    url: `/job-screening/${jobId}`,
    method: 'PATCH',
    data,
  });
}

export const fetchJobScreeningCandidates = (jobId, query="") => {
  return requestWithToken({
      url: `/job-screening/${jobId}/candidates${query}`,
      method: 'GET',
  });
}

export const closeJobScreeningById = (jobId) => {
  return requestWithToken({
      url: `/job-screening/${jobId}/close`,
      method: 'PATCH',
  });
}
