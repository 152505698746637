import {Box, Drawer, FormHelperText, Grid, IconButton, InputLabel} from "@mui/material";
import {Close} from "@mui/icons-material";
import "./drawer.scss";
import React, {useEffect, useState} from "react";
import Input from "../../elements/Input";
import RichMDXEditor from "../../elements/Editor/RichEditor";
import {CreateButton} from "../../elements/CreateButton";
import CustomAutoComplete from "../../elements/AutoComplete";
import {StyledChips} from "../../elements/Chip/NormalChip";
import {convertMarkdownToHTML} from "../../utils/globalFunctions";
import {useDispatch} from "react-redux";
import {sendMail} from "../../store/actions/mails"
import CircularProgress from "@mui/material/CircularProgress";
import * as mailsAction from "../../store/actions/mails";

const MailDrawer = ({ toggleDrawer, open, type, from, to, subject, body, direction }) => {
    const [state, setState] = useState({})
    const [error, setError] = useState({})
    const [mainError, setMainError] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!open) {
            setState({})
        } else {
            setState({fromMail: from, toMails: [...(to || [])], subject})
        }
    }, [open])

    const handleChange = (event) => {
        const {target: {name, value}} = event
        setState({...state, [name]: value})
    }

    const handleToChange = (event, value) => {
        setState({...state, toMails: value})
    }

    const handleRichTextEditorChange = (value) => {
        setState({...state, body: value})
    }

    const onSend = () => {
        const payload = {
            "from": state.fromMail,
            "to": state.toMails,
            "subject": state.subject,
            "body": `${type === 'forward' ? `${convertMarkdownToHTML(state.body)} ${body}` : convertMarkdownToHTML(state.body)}`
        }
        setIsLoading(true)
        dispatch(sendMail(payload)).then((res) => {
            if(direction === "outgoing") {
                dispatch(mailsAction.getMails(direction))
            }
            setIsLoading(false)
        }).catch((err) => {
            setMainError(err.message)
            setIsLoading(false)
        })
    }

    return (
        <Drawer
            anchor={"right"}
            open={open}
            onClose={toggleDrawer(false)}
        >
            <Box
                sx={{ width: 850 }}
                role="presentation"
            >
                <div className={"drawer-header"}>
                    <div>Mail</div>
                    <IconButton
                        aria-label="close"
                        onClick={toggleDrawer(false)}
                    >
                        <Close />
                    </IconButton>
                </div>
                <div className={"drawer-content"}>
                    <div className={"section-wrapper"}>
                        <Grid xs={12} md={12} lg={12} xl={12} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Input
                                    type="text"
                                    name="fromMail"
                                    variant="outlined"
                                    title="From"
                                    placeholder="Enter your mail"
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                    size="small"
                                    helperText={error.fromMail}
                                    value={state?.fromMail}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <CustomAutoComplete
                                    multiple
                                    id="tags-filled"
                                    options={[]}
                                    // defaultValue={state.toMails}
                                    value={[...(state.toMails || [])]}
                                    freeSolo
                                    onChange={handleToChange}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <StyledChips
                                                label={option}
                                                {...getTagProps({index})}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <Input
                                            {...params}
                                            type="text"
                                            name="toMails"
                                            variant="outlined"
                                            title="To"
                                            placeholder="Enter to mail"
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                            helperText={error.toMails}
                                            size="small"
                                            // value={aboutData?.description || ""}
                                            // onChange={handleInputChange}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Input
                                    type="text"
                                    name="subject"
                                    variant="outlined"
                                    title="Subject"
                                    placeholder="Enter Subject"
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                    size="small"
                                    helperText={error.subject}
                                    value={state?.subject}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} className={"mail-compose-body"}>
                                <InputLabel>Body</InputLabel>
                                {/* <RichEditor value={summaryInput} setValue={setSummaryInput} key={job?.summary} /> */}
                                {type === 'forward' ? <div className={"forward-body-container"} dangerouslySetInnerHTML={{__html: body}}/>:<><RichMDXEditor value={state.body || ""} setValue={handleRichTextEditorChange}/>
                                {
                                    error.body ?
                                    <FormHelperText className="error">
                                {"error"}
                                    </FormHelperText>
                                    : null
                                }</>}
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <div style={{display: 'flex', width: "100%"}}>
                                    {mainError ? (
                                        <FormHelperText className="error">
                                            {mainError}
                                        </FormHelperText>
                                    ) : null}
                                    <CreateButton style={{marginLeft:"auto"}} disabled={!!Object.keys(error).length} onClick={onSend}>
                                        {isLoading ? <CircularProgress size={20} color="inherit"/> : null}
                                        <span>Send</span>
                                    </CreateButton>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Box>
        </Drawer>
    );
};

export default MailDrawer;
