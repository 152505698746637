import * as actionTypes from './actionTypes';
import { fetchJobList } from "../../../services/job.service";

export const setJobData = (data) => {
  return {
    type: actionTypes.SET_JOBS,
    data,
  }
}

export const getJobs = (params="") => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_JOBS_LOADING });
    return fetchJobList(params).then(res => {
      dispatch(setJobData(res?.data?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}
