
import { styled } from "@mui/material/styles";
import { Button } from '@mui/material';

export const CreateButton = styled(Button)(() => ({
  color: '#FFFFFF',
  backgroundColor: '#283665 !important',
  '&:hover': {
    backgroundColor: '#283665',
  },
  ':disabled': {
    color: '#bfbfbf'
  }
}));
