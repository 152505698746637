import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

import { SET_MENU } from '../../store/actions/menu';

// project imports
import Header from './Header';
import Sidebar from './Sidebar';

import SEO from '../../elements/SEO';
import { useEffect } from 'react';
import { getSkills } from '../../store/actions';
import { getCandidatesRole } from '../../store/actions/candidate';

const drawerWidth = 260;

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, user }) => ({
    ...(user?.userType === 'admin' ? theme.typography.adminMainContent : theme.typography.mainContent),
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: user?.userType === 'admin' ? '0px' : '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const fullScreenRoute = [
    "/mails"
]

const Layout = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const {candidateId} = useParams();
  const location = useLocation();

  const {leftDrawerOpened, user} = useSelector((state) => {
      return {
        leftDrawerOpened: state.customization.opened,
        user: state?.auth?.user
      }
  });
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
      dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(()=>{
      dispatch(getSkills())
      dispatch(getCandidatesRole())
  },[])

  const fullPageRoutes = [
    `/candidate/${candidateId}`,
    `/404`,
    `/candidate-review/candidate-pdf/${candidateId}`,
    `/companySetup`,
  ];

  const hideMenuToggleRoutes = [
    `/companySetup`
  ]

  const fullPage = fullPageRoutes.includes(location.pathname);
  const isFullScreenRoute = fullScreenRoute?.find((value) => {
    return value.includes(location.pathname);
  });

  const hideMenuToggle =hideMenuToggleRoutes.includes(location.pathname);

  return (
    <Box sx={{ display: 'flex' }}>
      <SEO title="GeekifyMe" />
      <CssBaseline />
      {
        user?.userType !== 'admin' ? <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
                bgcolor: theme.palette.background.default,
                transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
            }}
        >
              <Toolbar style={{ padding: '8px' }}>
                <Header handleLeftDrawerToggle={handleLeftDrawerToggle} fullPage={fullPage} />
              </Toolbar>
        </AppBar> : null
      }

      {/* drawer */}
        {
            !fullPage ?
                <Sidebar
                    drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
                    drawerToggle={handleLeftDrawerToggle}
                /> : null
        }

      {/* main content */}
        <Main theme={theme} open={leftDrawerOpened} user={user}>
            <div style={{maxWidth: isFullScreenRoute ? "unset" : 1400, margin: "0 auto"}}>
                <Outlet/>
            </div>
        </Main>
    </Box>
  );
};

export default Layout;
