import * as actionTypes from './actionTypes';
import { fetchCandidateList, fetchCandidateRole } from "../../../services/candidate.service";

export const setCandidateData = (data) => {
  return {
    type: actionTypes.SET_CANDIDATES,
    data,
  }
}

export const setCandidateRole = (data) => {
  return {
    type: actionTypes.SET_CANDIDATES_ROLE,
    data,
  }
}

export const getCandidates = (params= "") => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_CANDIDATES_LOADING })
    return fetchCandidateList(params).then(res => {
      dispatch(setCandidateData(res?.data?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}

export const getCandidatesRole = (params= "") => {
  return dispatch => {
    return fetchCandidateRole(params).then(res => {
      dispatch(setCandidateRole(res?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}
