import axios from "axios";
import AuthService from "../services/auth.service";
import {store} from "../store";
import {logOutUser} from "../store/actions/auth";
import history from '../utils/customHistory';

let isRefreshing = false;
let failedQueue = [];

export const requestWithToken = (options) => {

    const header = {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
    }

    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: header
    })

    const logout = () => {
        store.dispatch(logOutUser()).then(()=>{
            history.push('/login');
            window.location.reload();
        })
    }

    const onSuccess = (response) => {
        return response.data;
    }

    const onError = async (error) => {
        if (error.request) {
            return Promise.reject(error.request);
        } else if (error?.response || error?.statusCode) {
            if (error?.response?.status === 405 || error?.statusCode === 405) {
                return Promise.reject(error.response || error.message);
            }
            if (error?.response?.status === 403 || error?.statusCode === 403) {
                if (options.url.includes('refresh-tokens')) {
                    logout()
                }
            } else if (error?.response?.status === 401 || error?.statusCode === 401) {
                //If refresh token expires
                logout();
            } else if (error?.response?.status === 404 || error?.statusCode === 404) {
                history.push('/404')
            } else if (error?.statusCode === 400) {
                history.push('/login')
            }
            return Promise.reject(!!error?.response?.data ? error?.response?.data : error?.response || error?.error || error?.message);
        } else {
            return Promise.reject(error.message);
        }
    }

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        })

        failedQueue = [];
    }

    instance.interceptors.response.use(function (response) {
        return response;
    },
        function (error) {
        if (!localStorage.getItem('assumeUser')) {
            const originalRequest = error.config;
            if (error.response.status === 401 && !originalRequest._retry) {

                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({
                            resolve,
                            reject
                        });
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                        .catch(err => {
                            return Promise.reject(err);
                        });
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise(function (resolve, reject) {
                    AuthService.callRefresh()
                        .then(({data}) => {
                            localStorage.setItem('accessToken', data.tokens.access.token);
                            localStorage.setItem('refreshToken', data.tokens.refresh.token);
                            // window.localStorage.setItem('token', data.token);
                            // window.localStorage.setItem('refreshToken', data.refreshToken);
                            //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
                            originalRequest.headers['Authorization'] = 'Bearer ' + data.tokens.access.token;
                            processQueue(null, data.accessToken);
                            resolve(axios(originalRequest));
                            return data
                        })
                        .catch((err) => {

                            // store.dispatch(logOutUser()).then(()=>{
                            //     history.push('/login');
                            //     window.location.reload();
                            // })
                            processQueue(err, null);
                            reject(err);
                        })
                        .finally((data) => {
                            isRefreshing = false;
                            if (data?.tokens?.access?.token) store.dispatch({type: "SET_AUTH_DATA", data})
                        });
                });
            }
        }
        return Promise.reject(error);
    });
    return instance(options).then(onSuccess).catch(onError);
}

export const simpleRequest = (options) => {
    const header = {
        'Content-Type': 'application/json',
        "Accept": "application/json",
    }

    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: header
    })
    const onSuccess = (response) => {
        return response.data;
    }

    const onError = async (error) => {
        if (error.response) {
            return Promise.reject(!!error.response.data ? error.response.data : error.response || error.message);
        } else if (error.request) {
            return Promise.reject(error.request);
        } else {
            return Promise.reject(error.message);
        }
    }

    return instance(options).then(onSuccess).catch(onError);
};

export const templateSimpleRequest = (options) => {
    const header = {
        'Content-Type': 'application/json',
        "Accept": "application/json",
    }

    const instance = axios.create({
        baseURL: process.env.REACT_APP_TEMPLATE_API_URL,
        headers: header
    })
    const onSuccess = (response) => {
        return response.data;
    }

    const onError = async (error) => {
        if (error.response) {
            return Promise.reject(!!error.response.data ? error.response.data : error.response || error.message);
        } else if (error.request) {
            return Promise.reject(error.request);
        } else {
            return Promise.reject(error.message);
        }
    }

    return instance(options).then(onSuccess).catch(onError);
};

export const dashboardSimpleRequest = (options) => {
    const header = {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
    }

    const instance = axios.create({
        baseURL: process.env.REACT_APP_DASHBOARD_API_URL,
        headers: header
    })
    const onSuccess = (response) => {
        return response.data;
    }

    const onError = async (error) => {
        if (error.response) {
            return Promise.reject(!!error.response.data ? error.response.data : error.response || error.message);
        } else if (error.request) {
            return Promise.reject(error.request);
        } else {
            return Promise.reject(error.message);
        }
    }

    return instance(options).then(onSuccess).catch(onError);
};

export const mailSimpleRequest = (options) => {
    const header = {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
    }

    const instance = axios.create({
        baseURL: process.env.REACT_APP_MAIL_SERVICE_BASE_URL,
        headers: header
    })
    const onSuccess = (response) => {
        return response.data;
    }

    const onError = async (error) => {
        if (error.response) {
            return Promise.reject(!!error.response.data ? error.response.data : error.response || error.message);
        } else if (error.request) {
            return Promise.reject(error.request);
        } else {
            return Promise.reject(error.message);
        }
    }

    return instance(options).then(onSuccess).catch(onError);
};
