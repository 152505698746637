import { fetchJobTemplates } from '../../../services/jobTemplate.service';
import * as actionTypes from './actionTypes';

export const setJobTemplatesData = (data) => {
  return {
    type: actionTypes.SET_JOB_TEMPLATES,
    data,
  }
}

export const getJobTemplates = (query="") => {
  return dispatch => {
    return fetchJobTemplates(query).then(res => {
      dispatch(setJobTemplatesData(res?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}