import * as actionTypes from '../../actions/tags/actionTypes';

export const initialState = {
  tags: [],
  isLoading: true
};

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TAGS:
      return {
        ...state,
        isLoading: false,
        tags: action.data
      };
    case actionTypes.SET_TAGS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};

export default tagReducer;
