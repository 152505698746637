import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonBase} from '@mui/material';

import {MENU_OPEN} from '../../../store/actions/menu';
import GeeksLogo from "../../../assets/images/logo-white.svg";

const LogoSection = (props) => {
    const defaultId = useSelector((state) => state.customization.defaultId);
    const dispatch = useDispatch();
    return (
        <ButtonBase disableRipple onClick={() => dispatch({type: MENU_OPEN, id: defaultId})} component={Link} to="/">
            <img src={GeeksLogo} alt={"geekifyme logo"} style={{width: props?.width || 200}}/>
        </ButtonBase>
    );
};

export default LogoSection;
