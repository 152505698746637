import * as actionTypes from './actionTypes';
import { fetchTagsList } from "../../../services/tags.service";

export const setTagsData = (data) => {
  return {
    type: actionTypes.SET_TAGS,
    data,
  }
}

export const getTags = (params="") => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_TAGS_LOADING })
    return fetchTagsList(params).then(res => {
      dispatch(setTagsData(res?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return [];
    });
  }
}
