import * as actionTypes from '../../actions/candidate/actionTypes';

export const initialState = {
  candidates: [],
  isLoading: true,
};

const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CANDIDATES_ROLE:
      return {
        ...state,
        candidatesRole: action.data
      }
    case actionTypes.SET_CANDIDATES:
      return {
        ...state,
        isLoading: false,
        candidates: action.data
      };
    case actionTypes.SET_CANDIDATES_LOADING:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
};

export default candidateReducer;
