import {requestWithToken} from "../apiConfigs/request";

export const fetchSkills = () => {
  return requestWithToken({
    url: '/skills/',
    method: 'GET'
  });
}

export const addSkills = (data) => {
  return requestWithToken({
    url: '/skills/',
    method: 'POST',
    data
  });
}
