import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar, Box, ButtonBase} from '@mui/material';

import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import {IconMenu2} from '@tabler/icons-react';
import {useLocation, useParams} from "react-router-dom";

const Header = ({handleLeftDrawerToggle, fullPage}) => {
    const theme = useTheme();
    const {candidateId} = useParams();
    const location = useLocation();

    // const fullPage = `/candidate/${candidateId}` === location.pathname || location.pathname.includes("404");

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{display: {xs: 'none', md: 'block'}, flexGrow: 1}}>
                    <LogoSection/>
                </Box>
                {
                    !fullPage ?
                        <ButtonBase sx={{borderRadius: '12px', overflow: 'hidden'}}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background: theme.palette.secondary.light,
                                    color: theme.palette.secondary.dark,
                                    '&:hover': {
                                        background: theme.palette.secondary.dark,
                                        color: theme.palette.secondary.light
                                    }
                                }}
                                onClick={handleLeftDrawerToggle}
                                color="inherit"
                            >
                                <IconMenu2 stroke={1.5} size="1.3rem"/>
                            </Avatar>
                        </ButtonBase> : null
                }
            </Box>
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{flexGrow: 1}}/>
            <ProfileSection/>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
