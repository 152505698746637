import Autocomplete from '@mui/material/Autocomplete';

const CustomAutoComplete = ({ ...props }) => {
  return (
    <Autocomplete
      {...props}
      componentsProps={{
        paper: {
          sx: {
            boxShadow: 3,
          },
        },
      }}
    />
  );
};

export default CustomAutoComplete;
