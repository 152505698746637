import * as actionTypes from '../../actions/proposal/actionTypes';

export const initialState = {
    proposals: [],
    isLoading: true,
};

const proposalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PROPOSALS:
            return {
                ...state,
                isLoading: false,
                proposals: action.data
            };
        default:
            return state;
    }
};

export default proposalReducer;
