import { useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';

// third-party
// project imports
import MainCard from '../../../../elements/Card';
import Transitions from '../../../../elements/Transitions';

// assets
import { IconLogout } from '@tabler/icons-react';
import { logOutUser } from '../../../../store/actions/auth';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const { customization, user, userBusiness } = useSelector((state) => {
      return {
        customization: state.customization,
        user: state?.auth?.user,
        userBusiness: state?.auth?.userBusiness,
      };
    });
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    const dispatch = useDispatch()

    const handleLogout = async () => {
        dispatch(logOutUser()).then(()=>{
            navigate("/login")
        })
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
      <>
        <Chip
          className={`${user?.userType === 'admin' ? 'fix-profile-section' : ''}`}
          sx={{
            height: '48px',
            alignItems: 'center',
            transition: 'all .2s ease-in-out',
            border: 'none',
            '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: 'transparent',
                background: `transparent !important`,
                color: 'transparent',
                '& svg': {
                    stroke: 'transparent'
                }
            },
            '& .MuiChip-label': {
                lineHeight: 0
            }
          }}
          icon={
              <Avatar
                  sx={{
                      ...theme.typography.mediumAvatar,
                      margin: '8px 0 8px 8px !important',
                      cursor: 'pointer',
                      backgroundColor: '#283665',
                      color: '#FFFFFF',
                      width: '40px',
                      height: '40px'
                  }}
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  color="inherit"
              >{user?.firstName ? user?.firstName?.charAt(0): ''}{user?.lastName ? user?.lastName?.charAt(0): ''}</Avatar>
          }
          variant="outlined"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="primary"
        />
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: user?.userType === 'admin' ? [25, 14] : [0, 14]
                }
              }
            ]
          }}
        >
          {({ TransitionProps }) => (
              <Transitions in={open} {...TransitionProps}>
                  <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                          <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                              <Box sx={{ p: 2 }} style={{ padding: '0px' }}>
                                <Stack>
                                    <Stack style={{ padding: '16px' }} direction="row" spacing={0.5} alignItems="center">
                                        <Typography variant="h4">Hello,</Typography>
                                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                                            {user?.firstName} {user?.lastName}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Divider sx={{ width: '100%', pr: 1, pl: 2, my: 0 }} />
                              </Box>

                              <Box sx={{ p: 2 }} style={{ padding: '0px' }}>
                                  <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 350,
                                        minWidth: 300,
                                        backgroundColor: theme.palette.background.paper,
                                        borderRadius: '10px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%'
                                        },
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5
                                        }
                                    }}
                                  >
                                    {user.userType === 'business' ?
                                      <ListItemButton
                                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                                        selected={selectedIndex === 3}
                                        onClick={() => {
                                          if (userBusiness.businessId) {
                                            navigate(`settings/business/${userBusiness.businessId}`);
                                          } else {
                                            navigate(`settings/business/create`);
                                          }
                                          handleToggle()
                                        }}
                                      >
                                        <Typography component='span' variant='h4' sx={{ fontWeight: 400 }}>
                                          Business
                                        </Typography>
                                      </ListItemButton> :
                                      null
                                    }
                                    {/* <ListItemButton
                                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                                      selected={selectedIndex === 0}
                                      onClick={(event) => handleListItemClick(event, 0, '/user/account-profile/profile1')}
                                    >
                                      <ListItemIcon>
                                          <IconSettings stroke={1.5} size="1.3rem" />
                                      </ListItemIcon>
                                      <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                    </ListItemButton> */}
                                    <ListItemButton
                                      sx={{ borderRadius: `${customization.borderRadius}px` }}
                                      selected={selectedIndex === 4}
                                      onClick={handleLogout}
                                    >
                                      <ListItemIcon>
                                          <IconLogout stroke={1.5} size="1.3rem" />
                                      </ListItemIcon>
                                      <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                    </ListItemButton>
                                  </List>
                              </Box>
                          </MainCard>
                      </ClickAwayListener>
                  </Paper>
              </Transitions>
            )}
        </Popper>
      </>
    );
};

export default ProfileSection;
