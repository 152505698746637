import * as actionTypes from '../../actions/jobTemplate/actionTypes';

export const initialState = {
  jobTemplates: [],
  isLoading: true
};

const jobTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_JOB_TEMPLATES:
      return {
        ...state,
        isLoading: false,
        jobTemplates: action.data
      };
    default:
      return state;
  }
};

export default jobTemplateReducer;
